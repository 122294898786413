var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-order__main" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "new-order__main-title" }, [
          _vm._v("Choose Pickup & Dropoff")
        ]),
        _c("div", { staticClass: "new-order__part" }, [
          _c("div", { staticClass: "new-order__customer-wrapper" }, [
            _vm._m(0),
            _c("div", { staticClass: "new-order__customer-inner" }, [
              _c("div", { staticClass: "new-order__customer-name" }, [
                _c("div", { staticClass: "new-order__customer-subtitle" }, [
                  _vm._v("Pickup")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "new-order__customer-title",
                    on: {
                      click: function($event) {
                        return _vm.openSearch("pickup")
                      }
                    }
                  },
                  [
                    _vm.pickup && _vm.pickup.businessName
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$dots(_vm.pickup.businessName, 20)))
                        ])
                      : _c(
                          "span",
                          { staticClass: "new-order__customer-desc" },
                          [_vm._v("Pickup destination")]
                        )
                  ]
                )
              ]),
              !_vm.customer.isOutsideOperativeArea
                ? _c(
                    "button",
                    {
                      staticClass: "button button--fill button--fill--blue",
                      class: { "button--fill--grey": _vm.pickupBtn },
                      on: {
                        click: function($event) {
                          return _vm.chooseMe("pickup")
                        }
                      }
                    },
                    [_vm._v(" Choose me ")]
                  )
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "new-order__customer-wrapper" }, [
            _vm._m(1),
            _c("div", { staticClass: "new-order__customer-inner" }, [
              _c("div", { staticClass: "new-order__customer-name" }, [
                _c("div", { staticClass: "new-order__customer-subtitle" }, [
                  _vm._v("Dropoff")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "new-order__customer-title",
                    on: {
                      click: function($event) {
                        return _vm.openSearch("dropOff")
                      }
                    }
                  },
                  [
                    _vm.dropOff && _vm.dropOff.businessName
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$dots(_vm.dropOff.businessName, 20))
                          )
                        ])
                      : _c(
                          "span",
                          { staticClass: "new-order__customer-desc" },
                          [_vm._v("Dropoff destination")]
                        )
                  ]
                )
              ]),
              !_vm.customer.isOutsideOperativeArea
                ? _c(
                    "button",
                    {
                      staticClass: "button button--fill button--fill--blue",
                      class: { "button--fill--grey": _vm.dropOffBtn },
                      on: {
                        click: function($event) {
                          return _vm.chooseMe("dropOff")
                        }
                      }
                    },
                    [_vm._v(" Choose me ")]
                  )
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "new-order__customer-wrapper" }, [
            _vm._m(2),
            _c("div", { staticClass: "new-order__customer-inner" }, [
              _c("div", { staticClass: "new-order__customer-name" }, [
                _c("div", { staticClass: "new-order__customer-subtitle" }, [
                  _vm._v("Billing")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "new-order__customer-title",
                    on: {
                      click: function($event) {
                        return _vm.openSearch("billing")
                      }
                    }
                  },
                  [
                    _vm.billing && _vm.billing.businessName
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$dots(_vm.billing.businessName, 20))
                          )
                        ])
                      : _c(
                          "span",
                          { staticClass: "new-order__customer-desc" },
                          [_vm._v("Billing destination")]
                        )
                  ]
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "button button--fill button--fill--blue",
                  on: {
                    click: function($event) {
                      return _vm.chooseMe("billing")
                    }
                  }
                },
                [_vm._v(" Choose me ")]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "new-order__part" }, [
          _c("div", { staticClass: "new-order__customer-wrapper" }, [
            _c("div", { staticClass: "new-order__customer-label" }, [
              _c("i", { staticClass: "ri-calendar-fill" }),
              _c("input", {
                staticClass: "new-order__customer-input",
                attrs: { min: _vm.localDate, type: "date" },
                domProps: { value: _vm.date },
                on: { change: _vm.changeDate }
              })
            ])
          ])
        ]),
        _c("FilesUpload")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--blue" },
      [_c("i", { staticClass: "ri-arrow-up-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--orange" },
      [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "icon-button icon-button--round icon-button--orange" },
      [_c("i", { staticClass: "ri-arrow-down-circle-line" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }