<template>
  <div class="new-order__part">
    <div class="new-order__files-title">
      Do you have a picture of the delivery?
    </div>
    <label class="new-order__files-label">
      <textarea
        class="new-order__files-textarea"
        placeholder="Start typing here..."
        :value="note"
        @input="changeNote"
      ></textarea>
    </label>
    <div class="new-order__files-file-wrapper">
      <img v-if="photo" :src="photo" class="new-order__files-file-photo" @click="file = photo = null" />
      <label v-else class="new-order__files-label-file">
        <input type="file" class="new-order__files-file" accept="image/*" @change="handleFileUpload($event)" />
        <div class="new-order__files-file-icon"><i class="ri-image-line"></i></div>
      </label>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

export default {
  name: 'FilesUploadCustomerOrder',
  data() {
    return {
      photo: null
    }
  },
  computed: {
    ...mapState({
      note: s => s.newOrder.note,
      file: s => s.newOrder.file
    })
  },
  mounted() {
    if (this.file) this.photo = window.URL.createObjectURL(this.file)
  },
  methods: {
    ...mapMutations({
      setNote: 'newOrder/setNote',
      setFile: 'newOrder/setFile'
    }),
    handleFileUpload(e) {
      this.photo = window.URL.createObjectURL(e.target.files[0])
      this.setFile(e.target.files[0])
    },
    changeNote(e) {
      this.setNote(e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
