var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-order__main" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "new-order__main-title" }, [
        _vm._v("Select Service")
      ]),
      _c("div", { staticClass: "new-order__part" }, [
        _c(
          "div",
          { key: _vm.listKey, staticClass: "service__list" },
          _vm._l(_vm.services, function(service) {
            return _c(
              "div",
              {
                key: service.id,
                staticClass: "service__item",
                class: { "service__item-active": service.active },
                on: {
                  click: function($event) {
                    return _vm.selectService(service)
                  }
                }
              },
              [
                _c("div", { staticClass: "service__item-name" }, [
                  _c("i", { class: _vm.cut(service.icon) }),
                  _vm._v(" " + _vm._s(service.name))
                ]),
                _c("div", { staticClass: "service__item-price" }, [
                  _vm._v(_vm._s(service.price) + " $")
                ])
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }