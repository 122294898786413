var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-order__part" }, [
    _c("div", { staticClass: "new-order__files-title" }, [
      _vm._v(" Do you have a picture of the delivery? ")
    ]),
    _c("label", { staticClass: "new-order__files-label" }, [
      _c("textarea", {
        staticClass: "new-order__files-textarea",
        attrs: { placeholder: "Start typing here..." },
        domProps: { value: _vm.note },
        on: { input: _vm.changeNote }
      })
    ]),
    _c("div", { staticClass: "new-order__files-file-wrapper" }, [
      _vm.photo
        ? _c("img", {
            staticClass: "new-order__files-file-photo",
            attrs: { src: _vm.photo },
            on: {
              click: function($event) {
                _vm.file = _vm.photo = null
              }
            }
          })
        : _c("label", { staticClass: "new-order__files-label-file" }, [
            _c("input", {
              staticClass: "new-order__files-file",
              attrs: { type: "file", accept: "image/*" },
              on: {
                change: function($event) {
                  return _vm.handleFileUpload($event)
                }
              }
            }),
            _vm._m(0)
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-order__files-file-icon" }, [
      _c("i", { staticClass: "ri-image-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }