<template>
  <div class="new-order__main">
    <div class="container">
      <div class="new-order__main-title">Select Service</div>
      <div class="new-order__part">
        <div :key="listKey" class="service__list">
          <div
            v-for="service of services"
            :key="service.id"
            class="service__item"
            :class="{'service__item-active': service.active}"
            @click="selectService(service)"
          >
            <div class="service__item-name"><i :class="cut(service.icon)"></i> {{ service.name }}</div>
            <div class="service__item-price">{{ service.price }} $</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

export default {
  name: 'PublicNewOrderService',
  data() {
    return {
      listKey: Math.random()
    }
  },
  computed: {
    ...mapState({
      services: s =>
        s.payment.services.map(s => {
          return {...s, active: false}
        })
    })
  },
  async created() {
    await this.getServices()
  },
  methods: {
    ...mapActions({
      getServices: 'payment/fetchServices'
    }),
    ...mapMutations({
      setService: 'newOrder/setService'
    }),
    selectService(service) {
      this.services.forEach(s => (s.active = false))
      service.active = true
      this.listKey = Math.random()
      this.setService(service)
    },
    cut(iconDom) {
      if (!iconDom) return
      const start = iconDom.indexOf('="') + 2
      const end = iconDom.indexOf('">')
      return iconDom.slice(start, end)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
