<template>
  <div class="page-wrapper">
    <div class="page-header">
      <div class="container">
        <div class="page-header-inner">
          <div class="new-order__steps">
            <div class="new-order__step" :class="{'new-order__step--active': step === 1}">
              <div class="new-order__step-number" @click="changeStep(1)">1</div>
              <span>General Info</span>
            </div>
            <div class="new-order__step" :class="{'new-order__step--active': step === 2}">
              <div class="new-order__step-number" @click="changeStep(2)">2</div>
              <span>Service</span>
            </div>
            <div class="new-order__step" :class="{'new-order__step--active': step === 3}">
              <div class="new-order__step-number" @click="changeStep(3)">3</div>
              <span>Confirmation</span>
            </div>
            <div class="new-order__step" :class="{'new-order__step--active': step === 4}">
              <div class="new-order__step-number" @click="changeStep(4)">4</div>
              <span>Payment Details</span>
            </div>
          </div>
          <div class="new-order__header-title">Booking New Order</div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="container">
        <div class="page-main-inner">
          <Customer v-if="step === 1" />
          <Service v-if="step === 2" />
          <Review v-if="step === 3" />
          <Payment v-if="step === 4" :is-loading="isLoading" />
          <div class="new-order__footer">
            <div
              class="new-order__button"
              :class="{
                'new-order__button--blue': nextBtnActive,
                'new-order__button--disabled': !nextBtnActive,
                'new-order__button--green': step === 3
              }"
              @click="nextStep"
            >
              <span class="new-order__button-text">{{ btnText }}</span>
              <i class="ri-arrow-right-circle-fill"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Customer from './Customer'
import Payment from '../Payment'
import Review from '../Review'
import Service from '../Service'
import StatusModal from '../Status'
import {mapGetters, mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'NewOrder',
  components: {Customer, Payment, Review, Service},
  data() {
    return {
      step: 1,
      steps: [
        {step: 1, access: true},
        {step: 2, access: false},
        {step: 3, access: false},
        {step: 4, access: false}
      ],
      loadStep: false,
      btnText: 'Next: Choose Payment Method',
      nextBtnActive: false,
      orderID: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      pickup: 'newOrder/pickup',
      dropOff: 'newOrder/dropOff',
      billing: 'newOrder/billing',
      service: 'newOrder/service',
      customerID: 'payment/customers/idCustomer',
      note: 'newOrder/note',
      file: 'newOrder/file',
      date: 'newOrder/date'
    }),
    ...mapState({
      card: s => s.card.activeCard
    })
  },
  watch: {
    step(s) {
      switch (s) {
        case 1: {
          this.btnText = 'Next: Choose Payment Method'
          if (this.pickup && this.dropOff && this.billing && this.step === 1) this.nextBtnActive = true
          else this.nextBtnActive = false
          break
        }
        case 2: {
          this.btnText = 'Select Service'
          if (this.service) this.nextBtnActive = true
          else this.nextBtnActive = false
          break
        }
        case 3: {
          this.btnText = 'Confirm & Book'
          break
        }
        case 4: {
          if (this.card?.paymentID && this.step === 4) this.nextBtnActive = true
          else this.nextBtnActive = false
          break
        }
      }
    },
    pickup(cus) {
      if (cus && this.dropOff && this.billing && this.step === 1) {
        this.nextBtnActive = true
        this.steps[1].access = true
      } else {
        this.nextBtnActive = false
        this.steps[1].access = false
      }
    },
    dropOff(cus) {
      if (cus && this.pickup && this.billing && this.step === 1) {
        this.nextBtnActive = true
        this.steps[1].access = true
      } else {
        this.nextBtnActive = false
        this.steps[1].access = false
      }
    },
    billing(cus) {
      if (cus && this.pickup && this.dropOff && this.step === 1) {
        this.nextBtnActive = true
        this.steps[1].access = true
      } else {
        this.nextBtnActive = false
        this.steps[1].access = false
      }
    },
    card(card) {
      if (card?.paymentID && this.step === 4) {
        this.nextBtnActive = true
        this.steps[3].access = true
      } else {
        this.nextBtnActive = false
        this.steps[2].access = false
      }
    },
    service(service) {
      if (service) {
        this.nextBtnActive = true
        this.steps[2].access = true
      } else {
        this.nextBtnActive = false
        this.steps[2].access = false
      }
    }
  },
  async created() {
    if (this.billing && this.pickup && this.dropOff && this.step === 1) {
      this.nextBtnActive = true
      this.steps[1].access = true
    }
    await this.$store.dispatch('payment/customers/fetchCustomer')
  },
  methods: {
    nextStep() {
      if (this.loadStep) return
      this.loadStep = true
      if (this.nextBtnActive && this.step === 1) {
        this.step++
      } else if (this.nextBtnActive && this.step === 2) {
        this.step++
      } else if (this.nextBtnActive && this.step === 3) {
        this.createOrder()
      } else if (this.nextBtnActive && this.step === 4) {
        this.payOrder()
      }
      setTimeout(() => {
        this.loadStep = false
      }, 1000)
    },
    changeStep(step) {
      const access = this.steps.find(s => s.step === step).access
      if (access) {
        this.step = step
      }
    },
    async createOrder() {
      const order = new FormData()
      order.append('routeDate', moment(this.date).format('YYYY-MM-DD'))
      order.append('serviceID', this.service?.id)
      order.append('companyID', process.env.VUE_APP_COMPANY_ID)
      order.append('pickUpID', this.pickup.id)
      order.append('dropOffID', this.dropOff.id)
      order.append('billingID', this.billing.id)
      this.note ? order.append('notes', this.note) : null
      this.file ? order.append('attachment', this.file) : null

      const orderInfo = await this.$store.dispatch('orders/create', order)
      const result = orderInfo.data.result

      this.orderID = result.id
      if (this.billing.id === this.customerID) {
        this.step++
        this.btnText = 'Pay'
      } else {
        this.openStatusScreen(result)
        this.reset()
      }
    },
    async payOrder() {
      this.isLoading = true
      const pmInfo = await this.$store.dispatch('payment/pay', {id: this.orderID, pmID: this.card.paymentID})
      const result = pmInfo.data.result
      this.openStatusScreen(result)
      this.reset()
    },
    openStatusScreen(result) {
      this.$modal.display(
        StatusModal,
        {
          result,
          payment: this.billing.id === this.customerID
        },
        {
          name: 'StatusModal',
          transition: 'none',
          width: 400,
          height: 400,
          classes: 'modal modal-order-status'
        }
      )
    },
    closePage() {
      this.$store.commit('newOrder/reset')
      this.$router.push('/public/orders')
    },
    reset() {
      this.step = 1
      this.steps = [
        {step: 1, access: true},
        {step: 2, access: false},
        {step: 3, access: false},
        {step: 4, access: false}
      ]
      this.orderID = null
      this.$store.commit('newOrder/reset')
      this.$store.commit('card/reset')
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
