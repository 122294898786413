var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "new-order__part" }, [
    _vm.file
      ? _c("div", { staticClass: "new-order-files__item" }, [
          _c(
            "span",
            {
              staticClass: "new-order-file__type",
              class: {
                "new-order-file__type--jpeg": _vm.fileData.type === "jpeg",
                "new-order-file__type--png": _vm.fileData.type === "png"
              }
            },
            [_vm._v(" " + _vm._s(_vm.fileData.type) + " ")]
          ),
          _c("div", { staticClass: "new-order-file__info" }, [
            _c("div", { staticClass: "new-order-file__name" }, [
              _vm._v(_vm._s(_vm.$dots(_vm.fileData.name, 50, false)))
            ]),
            _c("span", { staticClass: "new-order-file__date" }, [
              _vm._v(_vm._s(_vm.fileData.date))
            ]),
            _c("div", { staticClass: "new-order-file__dot" }),
            _c("span", { staticClass: "new-order-file__size" }, [
              _vm._v(_vm._s(_vm.fileData.size) + " Mb")
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "icon-button icon-button--round",
              on: { click: _vm.del }
            },
            [_c("i", { staticClass: "ri-delete-bin-fill" })]
          )
        ])
      : _c(
          "label",
          { staticClass: "new-order-files__label", attrs: { id: "dropbox" } },
          [
            _c("input", {
              staticClass: "new-order-files__input",
              attrs: { type: "file", accept: "image/*" },
              on: { change: _vm.handleFileUpload }
            }),
            _c("i", { staticClass: "ri-image-fill" }),
            _c("span", { staticClass: "new-order-files__text" }, [
              _vm._v(" Click here or drag file to upload ")
            ])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }