<template>
  <div class="new-order__main">
    <div class="container">
      <div class="new-order__main-title">Choose Pickup & Dropoff</div>
      <div class="new-order__part">
        <div class="new-order__customer-wrapper">
          <div class="icon-button icon-button--round icon-button--blue">
            <i class="ri-arrow-up-circle-line"></i>
          </div>
          <div class="new-order__customer-inner">
            <div class="new-order__customer-name">
              <div class="new-order__customer-subtitle">Pickup</div>
              <div class="new-order__customer-title" @click="openSearch('pickup')">
                <span v-if="pickup && pickup.businessName">{{ $dots(pickup.businessName, 20) }}</span>
                <span v-else class="new-order__customer-desc">Pickup destination</span>
              </div>
            </div>
            <button
              v-if="!customer.isOutsideOperativeArea"
              class="button button--fill button--fill--blue"
              :class="{'button--fill--grey': pickupBtn}"
              @click="chooseMe('pickup')"
            >
              Choose me
            </button>
          </div>
        </div>

        <div class="new-order__customer-wrapper">
          <div class="icon-button icon-button--round icon-button--orange">
            <i class="ri-arrow-down-circle-line"></i>
          </div>
          <div class="new-order__customer-inner">
            <div class="new-order__customer-name">
              <div class="new-order__customer-subtitle">Dropoff</div>
              <div class="new-order__customer-title" @click="openSearch('dropOff')">
                <span v-if="dropOff && dropOff.businessName">{{ $dots(dropOff.businessName, 20) }}</span>
                <span v-else class="new-order__customer-desc">Dropoff destination</span>
              </div>
            </div>
            <button
              v-if="!customer.isOutsideOperativeArea"
              class="button button--fill button--fill--blue"
              :class="{'button--fill--grey': dropOffBtn}"
              @click="chooseMe('dropOff')"
            >
              Choose me
            </button>
          </div>
        </div>

        <div class="new-order__customer-wrapper">
          <div class="icon-button icon-button--round icon-button--orange">
            <i class="ri-arrow-down-circle-line"></i>
          </div>
          <div class="new-order__customer-inner">
            <div class="new-order__customer-name">
              <div class="new-order__customer-subtitle">Billing</div>
              <div class="new-order__customer-title" @click="openSearch('billing')">
                <span v-if="billing && billing.businessName">{{ $dots(billing.businessName, 20) }}</span>
                <span v-else class="new-order__customer-desc">Billing destination</span>
              </div>
            </div>
            <button class="button button--fill button--fill--blue" @click="chooseMe('billing')">
              Choose me
            </button>
          </div>
        </div>
      </div>
      <div class="new-order__part">
        <div class="new-order__customer-wrapper">
          <div class="new-order__customer-label">
            <i class="ri-calendar-fill"></i>
            <input :value="date" :min="localDate" type="date" class="new-order__customer-input" @change="changeDate" />
          </div>
        </div>
      </div>
      <FilesUpload />
    </div>
  </div>
</template>

<script>
import SearchModal from './SearchCustomer'
import FilesUpload from './FilesUpload'
import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
  name: 'PublicNewOrderCustomer',
  components: {FilesUpload},
  data() {
    return {
      localDate: moment().format('YYYY-MM-DD'),
      dropOffBtn: false,
      pickupBtn: false
    }
  },
  computed: {
    ...mapGetters({
      customer: 'payment/customers/customer',
      pickup: 'newOrder/pickup',
      dropOff: 'newOrder/dropOff',
      billing: 'newOrder/billing',
      customerID: 'payment/customers/idCustomer',
      note: 'newOrder/note',
      file: 'newOrder/file',
      date: 'newOrder/date'
    })
  },
  watch: {
    pickup(cus) {
      this.customerID !== cus.id ? (this.dropOffBtn = false) : null
    },
    dropOff(cus) {
      this.customerID !== cus.id ? (this.pickupBtn = false) : null
    }
  },
  mounted() {
    this.$store.commit('newOrder/setDate', this.localDate)
  },
  methods: {
    openSearch(type) {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}
      this.$modal.display(
        SearchModal,
        {
          type,
          pickup: this.pickup,
          dropOff: this.dropOff
        },
        {
          name: 'SearchPublicCustomerModal',
          transition: 'none',
          ...modalHeight,
          classes: 'modal modal-search-public-customer'
        }
      )
    },
    async chooseMe(type) {
      const customer = this.$store.getters['payment/customers/customer']
      if (type === 'pickup' && !this.pickupBtn) {
        this.$store.commit('newOrder/setPickup', customer)
        this.dropOffBtn = true
        this.pickupBtn = false
      } else if (type === 'dropOff' && !this.dropOffBtn) {
        this.$store.commit('newOrder/setDropOff', customer)
        this.pickupBtn = true
        this.dropOffBtn = false
      } else if (type === 'billing') {
        this.$store.commit('newOrder/setBilling', customer)
      }
    },
    changeDate(e) {
      this.$store.commit('newOrder/setDate', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
