<template>
  <div class="new-order__main">
    <div class="container">
      <div class="new-order__main-title">Review & Book</div>
      <div class="new-order__part">
        <div v-if="pickup" class="new-order__customer-wrapper">
          <div class="new-order__customer-type">Pickup</div>
          <div class="new-order__customer">
            <div class="icon-button icon-button--round icon-button--orange">
              <i class="ri-arrow-down-circle-line"></i>
            </div>
            <div class="new-order__customer-info">
              <div class="new-order__customer-name">{{ $dots(pickup.businessName, numChar) }}</div>
              <div class="new-order__customer-address">{{ $dots(pickup.address, numChar) }}</div>
            </div>
          </div>
        </div>
        <div v-if="dropOff" class="new-order__customer-wrapper">
          <div class="new-order__customer-type">Dropoff</div>
          <div class="new-order__customer">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-arrow-up-circle-line"></i>
            </div>
            <div class="new-order__customer-info">
              <div class="new-order__customer-name">{{ $dots(dropOff.businessName, numChar) }}</div>
              <div class="new-order__customer-address">{{ $dots(dropOff.address, numChar) }}</div>
            </div>
          </div>
        </div>
        <div v-if="billing" class="new-order__customer-wrapper">
          <div class="new-order__customer-type">Billing</div>
          <div class="new-order__customer">
            <div class="icon-button icon-button--round icon-button--blue">
              <i class="ri-money-dollar-circle-line"></i>
            </div>
            <div class="new-order__customer-info">
              <div class="new-order__customer-name">{{ $dots(billing.businessName, numChar) }}</div>
              <div class="new-order__customer-address">{{ $dots(billing.address, numChar) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="new-order__part">
        <div v-if="photo" class="new-order__photo-wrapper">
          <div class="new-order__photo-title">Photo</div>
          <div class="new-order__photo">
            <el-image class="new-order__photo-img" :src="photo" :preview-src-list="[photo]" fit="cover" />
          </div>
        </div>
        <div v-if="note" class="new-order__note-wrapper">
          <div class="new-order__note-title">Note</div>
          <div class="new-order__note">{{ note }}</div>
        </div>
      </div>
      <div class="new-order__part">
        <div class="new-order__card-wrapper">
          <div class="new-order__card-total">
            <div class="new-order__card-title">Service Price</div>
            <div v-if="cost" class="new-order__card-price">${{ cost.servicePrice }}</div>
            <div v-else class="new-order__card-price">—</div>
          </div>
          <div class="new-order__card-total">
            <div class="new-order__card-title">Fuel Surcharge</div>
            <div v-if="cost" class="new-order__card-price">${{ cost.fuelSurcharge }}</div>
            <div v-else class="new-order__card-price">—</div>
          </div>
          <div class="new-order__card-total">
            <div class="new-order__card-title">HST</div>
            <div v-if="cost" class="new-order__card-price">${{ cost.hst }}</div>
            <div v-else class="new-order__card-price">—</div>
          </div>
          <div class="new-order__card-total">
            <div class="new-order__card-title">Total</div>
            <div v-if="cost" class="new-order__card-price">${{ cost.total }}</div>
            <div v-else class="new-order__card-price">—</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'PublicNewOrderReview',
  data() {
    return {
      photo: null,
      numChar: 40
    }
  },
  computed: {
    ...mapState({
      pickup: s => s.newOrder.pickup,
      dropOff: s => s.newOrder.dropOff,
      billing: s => s.newOrder.billing,
      customerID: s => s.newOrder.customerID,
      service: s => s.newOrder.service,
      note: s => s.newOrder.note,
      file: s => s.newOrder.file,
      date: s => s.newOrder.date,
      cost: s => s.payment.cost
    })
  },
  async created() {
    this.pickup ? null : this.$router.push('/public/orders')
    const mobile = document.documentElement.clientWidth <= 480
    mobile ? (this.numChar = 40) : (this.numChar = 60)
    await this.getCost(this.service.id)
  },
  mounted() {
    if (this.file) this.photo = window.URL.createObjectURL(this.file)
  },
  methods: {
    ...mapActions({
      getCost: 'payment/getCost'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
